<template>
    <v-list two-line class="clusters-container" :style="{height: height + 'px'}">
        <draggable
            handle=".handle"
            v-bind="{ group: 'ITEMS' }"
            v-model="filterItems"
            @change="change"
            style="height:100%"
        >
            <v-list-item
                v-for="cluster in filterItems"
                :key="cluster.IDGruppo"
                :disabled="readonly"
            >
                <v-list-item-action>
                    <v-icon class="handle">mdi-drag</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title
                        v-html="
                            `${cluster.Gruppo}`
                        "
                    ></v-list-item-title>
                    <v-list-item-subtitle
                        v-html="
                            `modificato da <b>${cluster.UtenteUltimaModifica}</b> il <i>${cluster.DataUltimaModifica}</i>`
                        "
                    ></v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </draggable>
    </v-list>
</template>

<script>
import draggable from 'vuedraggable'

export default {
    props: ["clusters", 'search', 'readonly', 'height'],

    data: () => ({
    }),

    components: {
        draggable
    },

    computed: {
        filterItems: {
            get: function() {
                const filteredClusters = this.clusters.filter(cluster => {
                    const checkText =
                        this.search.length === 0
                            ? true
                            : cluster.Gruppo.toLowerCase().indexOf(
                                  this.search.toLowerCase()
                              ) !== -1;

                    return checkText;
                });
                this.$emit('currentSelection', filteredClusters);
                return filteredClusters;
            },

            set: function(newValues) {
            }
        }
    },

    methods: {
        change(evt) {
            /* eslint-disable no-empty */
            // console.log(evt, Object.keys(evt))
            const action = Object.keys(evt)[0]
            if (action !== 'added' && action !== 'removed') {
                return
            }
            const element = evt[action].element
            if (action === 'added') {
                const newClusters = this.clusters
                const index = evt[action].newIndex
                newClusters.splice(index, 0, element)
                this.$emit('update:clusters', newClusters)
            }
            else { // removed
                // console.log(evt[action].element)
                const newClusters = this.clusters.filter(cluster => {
                    return cluster.IDGruppo !== element.IDGruppo
                })
                this.$emit('update:clusters', newClusters)
            }
        }
    }
};
</script>

<style scoped>
    .clusters-container {
    /*    height: 435px;*/
        overflow-y: scroll;
        padding-bottom: 96px;
    }

    .v-list-item {
        cursor: pointer;
        user-select: none;
    }

    .v-list-item__action {
        margin-right: 16px !important;
    }

    /* .handle {
        cursor: move !important;
        cursor: -webkit-grabbing !important;
    } */

    .handle:hover {
        cursor: pointer;
    }

    .changed {
        background: rgba(255,0,0,0.1);
    }
</style>
